export const SetLoginLocation = (location: string) => {
  if (location && location.length > 1 && !location.startsWith("/?")) {
    localStorage.setItem("loginLocation", location);
  }
};

export const GetLoginLocation = () => {
  const location = localStorage.getItem("loginLocation");

  if (location && location.length > 0) {
    localStorage.removeItem("loginLocation");
    return location;
  }

  return null;
};
