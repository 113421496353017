import {
  CustomRenderType,
  HeaderType,
  TableDataType,
} from "@/types/common/tableTypes";
import { ProcessContent as ProcessRealContent } from "@/components/common/table/utils";
import clsx from "clsx";
import { DashboardColumnTypes } from "@/enums/dashboard";
import { SupportedCountries } from "@/enums/internationalization";

type DashboardContentCardData = {
  keyValue: string;
  headers: HeaderType<DashboardColumnTypes>[];
  data: TableDataType<DashboardColumnTypes> | null;
  commonTranslation: (key: string) => string;
  country: SupportedCountries;
  customRender?: CustomRenderType<DashboardColumnTypes>;
};

function ProcessContent(
  key: string,
  header: HeaderType<DashboardColumnTypes>,
  data: TableDataType<DashboardColumnTypes> | null,
  commonTranslation: (key: string) => string,
  country: SupportedCountries,
  customRender?: CustomRenderType<DashboardColumnTypes>
) {
  if (data === null) {
    return (
      <div
        key={key + "loader"}
        className={clsx(
          "h-2.5",
          "my-3",
          "bg-gray-300",
          "rounded-full",
          "w-24",
          "animate-pulse"
        )}
      />
    );
  }

  return ProcessRealContent(
    key,
    header,
    data,
    false,
    commonTranslation,
    country,
    customRender
  );
}

export default function DashboardContentCard({
  keyValue,
  headers,
  data,
  commonTranslation,
  country,
  customRender,
}: DashboardContentCardData) {
  return (
    <div
      key={keyValue}
      className={clsx("w-full", "rounded-xl", "p-3", "bg-panel")}
    >
      <div
        className={clsx(
          "text-2xl",
          "m-[-0.75rem]",
          "rounded-t-xl",
          "bg-gray-100",
          "px-3",
          "py-1"
        )}
      >
        {ProcessContent(
          `cc_${keyValue}_0`,
          headers[0],
          data,
          commonTranslation,
          country,
          customRender
        )}
      </div>
      <div className={clsx("grid", "grid-cols-2", "gap-4", "mt-6", "mb-2")}>
        {ProcessContent(
          `cc_${keyValue}_1`,
          headers[1],
          data,
          commonTranslation,
          country,
          customRender
        )}
        {ProcessContent(
          `cc_${keyValue}_3`,
          headers[3],
          data,
          commonTranslation,
          country,
          customRender
        )}
      </div>
      {ProcessContent(
        `cc_${keyValue}_4`,
        headers[4],
        data,
        commonTranslation,
        country,
        customRender
      )}
      {ProcessContent(
        `cc_${keyValue}_2`,
        headers[2],
        data,
        commonTranslation,
        country,
        customRender
      )}
      <div className={clsx("mt-2", "text-sm")}>
        {ProcessContent(
          `cc_${keyValue}_5`,
          headers[5],
          data,
          commonTranslation,
          country,
          customRender
        )}
      </div>
    </div>
  );
}
