import Table from "@/components/common/table";
import { DashboardColumnTypes, CallCategories } from "@/enums/dashboard";
import {
  HeaderType,
  TableCellType,
  TableDataType,
} from "@/types/common/tableTypes";
import clsx from "clsx";
import {
  Table as FTable,
  Pagination as PaginationControl,
  Tooltip,
} from "flowbite-react";
import Link from "next/link";
import DashboardContentCard from "./DashboardContentCard";
import { Pagination } from "@/types/common/pagination";
import { ToSecondsLabel } from "@lula-technologies-inc/lul";
import { ToFlag, ToRating } from "./[callId]/CallerInfoHelpers";
import { useTranslation } from "react-i18next";
import { useProfile } from "@/hooks/useProfile";
import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import {
  CallInternalNoteUpdateRequest,
  CallInternalNoteUpdateResponse,
} from "@/types/call";
import { api } from "@/utils/common/api";
import { useEffect, useState } from "react";
import CallRatingModal from "./[callId]/CallRatingModal";
import Button from "@/components/library/Button";
import { useAlert } from "@/hooks/useAlert";
import Icon, { ICON_SIZES } from "@/components/library/Icon";
import { UserRoles } from "@/enums/user";

type DashboardContentProps = {
  data: TableDataType<DashboardColumnTypes>[] | null;
  pagination: Pagination;
  setPage: (page: number) => void;
};

export default function DashboardContent({
  data,
  pagination,
  setPage,
}: DashboardContentProps) {
  const { getToken, role } = useAuthContext();
  const { setIsSaving } = useAlert();
  const { t } = useTranslation("calls");
  const { t: ct } = useTranslation("common");
  const { country } = useProfile();

  const [calls, setCalls] = useState<
    TableDataType<DashboardColumnTypes>[] | null
  >(data);
  const [callRating, setCallRating] = useState<{
    id: string;
    value: number;
  } | null>(null);

  useEffect(() => {
    setCalls(data);
  }, [data]);

  const Headers: HeaderType<DashboardColumnTypes>[] = [
    {
      name: DashboardColumnTypes.Caller,
      label: (
        <>
          <span className="whitespace-nowrap">{t("duration")} /</span>
          <br />
          {t("caller")}
        </>
      ),
      type: TableCellType.React,
    },
    {
      name: DashboardColumnTypes.Category,
      label: (
        <>
          <span className="whitespace-nowrap">{t("category")} /</span>
          <br />
          {t("callback")}
        </>
      ),
      type: TableCellType.React,
    },
    {
      name: DashboardColumnTypes.Reason,
      label: t("reason"),
      type: TableCellType.Text,
    },
    {
      name: DashboardColumnTypes.Ratings,
      label: (
        <>
          <span className="whitespace-nowrap">{t("callRating")} /</span>
          <br />
          <span className="whitespace-nowrap">{t("yourRating")}</span>
        </>
      ),
      type: TableCellType.React,
    },
    {
      name: DashboardColumnTypes.Flags,
      label: (
        <>
          <span className="whitespace-nowrap">{t("flagged")} /</span>
          <br />
          {t("reviewed")}
        </>
      ),
      type: TableCellType.React,
    },
    {
      name: DashboardColumnTypes.Date,
      label: t("date"),
      type: TableCellType.DateSeparateLines,
    },
  ];

  const CustomRenderer = {
    [DashboardColumnTypes.Caller]: (
      header: HeaderType<DashboardColumnTypes>,
      data: any,
      isCell: boolean,
      key: string
    ) => {
      const isInbound = data.direction === "inbound";
      const isOutbound = data.direction === "outbound";

      if (isCell) {
        return (
          <FTable.Cell key={key} className="px-4">
            <span
              className={clsx("mr-2", {
                hidden: isOutbound,
              })}
            >
              <Icon icon="phone-arrow-down-left" size={ICON_SIZES.LG} />
            </span>
            <span
              className={clsx("mr-2", {
                hidden: isInbound,
              })}
            >
              <Icon icon="phone-arrow-up-right" size={ICON_SIZES.LG} />
            </span>
            {data.duration > 0 ? ToSecondsLabel(data.duration) : "-"}
            <br />
            {data.caller}
          </FTable.Cell>
        );
      }

      return (
        <div key={key}>
          <span
            className={clsx("mr-2", {
              hidden: isOutbound,
            })}
          >
            <Icon icon="phone-arrow-down-left" />
          </span>
          <span
            className={clsx("mr-2", {
              hidden: isInbound,
            })}
          >
            <Icon icon="phone-arrow-up-right" />
          </span>
          <span className="text-sm">
            {data.duration > 0 ? ToSecondsLabel(data.duration) : "-"}
          </span>
          <span
            className={clsx(
              "float-right",
              "text-medium",
              "mt-1",
              "overflow-hidden",
              "whitespace-nowrap",
              "text-ellipsis",
              "max-w-[75%]"
            )}
          >
            {data.caller}
          </span>
        </div>
      );
    },
    [DashboardColumnTypes.Category]: (
      header: HeaderType<DashboardColumnTypes>,
      data: any,
      isCell: boolean,
      key: string
    ) => {
      let icon: "question" | "money-bill-trend-up" | "headset" = "question";
      let label = "Unknown";

      switch (data[header.name].toLowerCase()) {
        case CallCategories.Support:
          icon = "headset";
          label = ct("support");
          break;
        case CallCategories.Sales:
          icon = "money-bill-trend-up";
          label = ct("sales");
          break;
      }

      const callback =
        data.callback === "yes" ||
        data.callback === true ||
        data.callback === "true";

      if (isCell) {
        return (
          <FTable.Cell key={key} className="px-4">
            <span className={clsx("whitespace-nowrap")}>
              <Icon icon={icon} />
              <span className={clsx("ml-2")}>{label}</span>
              <br />
              <div
                key={key}
                className={clsx(
                  "rounded-full",
                  "text-center",
                  "px-2.5",
                  "py-0.5",
                  "text-xs",
                  "font-semibold",
                  "mt-1",
                  {
                    "text-green-600": callback,
                    "bg-green-100": callback,
                    "text-gray-600": !callback,
                    "bg-gray-200": !callback,
                  }
                )}
              >
                {callback ? ct("yes") : ct("no")}
              </div>
            </span>
          </FTable.Cell>
        );
      }

      return (
        <div key={key}>
          <Icon icon={icon} />
          <span className={clsx("ml-2")}>{label}</span>
          <br />
          <div
            key={key}
            className={clsx(
              "rounded-full",
              "text-center",
              "px-2.5",
              "py-0.5",
              "text-xs",
              "font-semibold",
              "mt-1",
              {
                "text-green-600": callback,
                "bg-green-100": callback,
                "text-gray-600": !callback,
                "bg-gray-200": !callback,
              }
            )}
          >
            {callback ? t("yesCallback") : t("noCallback")}
          </div>
        </div>
      );
    },
    [DashboardColumnTypes.Ratings]: (
      header: HeaderType<DashboardColumnTypes>,
      data: any,
      isCell: boolean,
      key: string
    ) => {
      if (isCell) {
        return (
          <FTable.Cell key={key} className="px-4">
            <span className={clsx("whitespace-nowrap")}>
              <Tooltip
                content={`${t("callerRating")}: ${
                  data.callerRating > 0
                    ? `${data.callerRating} ${t("stars")}`
                    : t("noRating")
                }`}
              >
                {ToRating(data.callerRating, false, "-")}
              </Tooltip>
            </span>
            <Button
              compact
              type="tertiary"
              className={clsx("whitespace-nowrap")}
              disabled={
                role === UserRoles.Integration || role === UserRoles.ReadOnly
              }
              onClick={() =>
                setCallRating({ id: data.id, value: data.yourRating })
              }
            >
              <Tooltip
                content={`${t("yourRating")}: ${
                  data.yourRating > 0
                    ? `${data.yourRating} ${t("stars")}`
                    : t("noRating")
                }`}
              >
                {ToRating(data.yourRating, false, t("noRating"))}
              </Tooltip>
            </Button>
          </FTable.Cell>
        );
      }

      return (
        <div key={key}>
          <span className={clsx("whitespace-nowrap")}>
            <Icon icon="phone-volume" className={clsx("mr-2", "w-5")} />
            {ToRating(data.callerRating, false, t("noRating"))}
          </span>
          <Button
            compact
            type="tertiary"
            className={clsx("whitespace-nowrap")}
            disabled={
              role === UserRoles.Integration || role === UserRoles.ReadOnly
            }
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              setCallRating({ id: data.id, value: data.yourRating });
            }}
          >
            <Icon icon="building" className={clsx("mr-2", "w-5")} />
            {ToRating(data.yourRating, false, t("noRating"))}
          </Button>
        </div>
      );
    },
    [DashboardColumnTypes.Flags]: (
      header: HeaderType<DashboardColumnTypes>,
      data: any,
      isCell: boolean,
      key: string
    ) => {
      //const trueColor = isFlagType ? "red" : "green";

      if (isCell) {
        return (
          <FTable.Cell key={key} className="px-4">
            <div>
              <Button
                compact
                type="tertiary"
                className={clsx("whitespace-nowrap", {
                  "bg-red-300": data.flagged,
                  "hover:bg-red-200": data.flagged,
                })}
                disabled={
                  role === UserRoles.Integration || role === UserRoles.ReadOnly
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  handleInternalNoteUpdate(
                    {
                      isFlagged: !data.flagged,
                    },
                    data.id
                  );
                }}
              >
                <Tooltip
                  content={`${t("flagged")}: ${
                    data.flagged ? t("callFlagged") : t("callNotFlagged")
                  }`}
                >
                  {ToFlag(data.flagged, true, ct, undefined, true)}
                </Tooltip>
              </Button>
            </div>
            <div className={clsx("mt-2")}>
              <Button
                compact
                type="tertiary"
                disabled={
                  role === UserRoles.Integration || role === UserRoles.ReadOnly
                }
                className={clsx("whitespace-nowrap", {
                  "bg-green-300": data.reviewed,
                  "hover:bg-green-200": data.reviewed,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  handleInternalNoteUpdate(
                    {
                      isReviewed: !data.reviewed,
                    },
                    data.id
                  );
                }}
              >
                <Tooltip
                  content={`${t("reviewed")}: ${
                    data.reviewed ? t("callReviewed") : t("callNotReviewed")
                  }`}
                >
                  {ToFlag(data.reviewed, false, ct, undefined, true)}
                </Tooltip>
              </Button>
            </div>
          </FTable.Cell>
        );
      }

      return (
        <div key={key} className={clsx("grid", "gap-4", "grid-cols-2", "mb-3")}>
          <div>
            {ToFlag(
              data.flagged,
              true,
              ct,
              !!data.flagged
                ? t("callFlaggedMobile")
                : t("callNotFlaggedMobile")
            )}
          </div>
          <div>
            {ToFlag(
              data.reviewed,
              false,
              ct,
              !!data.reviewed
                ? t("callReviewedMobile")
                : t("callNotReviewedMobile")
            )}
          </div>
        </div>
      );
    },
  };

  const handleInternalNoteUpdate = async (
    update: CallInternalNoteUpdateRequest,
    callId: string
  ): Promise<CallInternalNoteUpdateResponse | null> => {
    setIsSaving(true, t(update.yourRating ? "savingRating" : "savingFlag"));

    const response = await api<
      CallInternalNoteUpdateRequest,
      CallInternalNoteUpdateResponse
    >(getToken, `/api/call/${callId}/internal-notes`, "POST", update);

    setIsSaving(false, "");

    if (!response) {
      return null;
    }

    setCalls((prev) => {
      if (!prev) {
        return null;
      }

      return prev.map((c) => {
        if ((c as any).id === callId) {
          return {
            ...c,
            ...response,
          };
        }
        return c;
      });
    });

    return response;
  };

  return (
    <div className={clsx("w-full", "mt-6")}>
      <div
        className={clsx(
          "hidden",
          "min-[1025px]:block",
          "relative",
          "w-full",
          "overflow-auto"
        )}
      >
        <Table
          headers={Headers}
          data={calls}
          commonTranslation={ct}
          country={country}
          customRender={CustomRenderer}
          cellClassName="px-4"
        />
      </div>
      <div
        className={clsx(
          "min-[1025px]:hidden",
          "relative",
          "w-full",
          "overflow-auto",
          "grid",
          "min-[800px]:grid-cols-2",
          "grid-cols-1",
          "gap-4"
        )}
      >
        {calls === null && (
          <>
            <DashboardContentCard
              keyValue="card_d1"
              headers={Headers}
              data={null}
              commonTranslation={ct}
              country={country}
            />
            <DashboardContentCard
              keyValue="card_d2"
              headers={Headers}
              data={null}
              commonTranslation={ct}
              country={country}
            />
            <DashboardContentCard
              keyValue="card_d3"
              headers={Headers}
              data={null}
              commonTranslation={ct}
              country={country}
            />
            <DashboardContentCard
              keyValue="card_d4"
              headers={Headers}
              data={null}
              commonTranslation={ct}
              country={country}
            />
          </>
        )}
        {calls !== null &&
          calls.length > 0 &&
          calls.map((d, i) => {
            if (d.action) {
              return (
                <Link href={d.action} key={"cl" + i}>
                  <DashboardContentCard
                    keyValue={"card_a" + i}
                    headers={Headers}
                    data={d}
                    customRender={CustomRenderer}
                    commonTranslation={ct}
                    country={country}
                  />
                </Link>
              );
            }
            return (
              <DashboardContentCard
                key={"card_" + i}
                keyValue={"card_" + i}
                headers={Headers}
                data={d}
                customRender={CustomRenderer}
                commonTranslation={ct}
                country={country}
              />
            );
          })}
        {calls !== null && calls.length <= 0 && (
          <p className={clsx("text-center", "p-4")}>
            <Icon icon="phone-slash" />
            <span className={clsx("mx-4")}>No Calls Found</span>
            <Icon icon="phone-slash" />
          </p>
        )}
      </div>
      {calls !== null && calls.length > 0 && (
        <PaginationControl
          currentPage={pagination.page}
          totalPages={pagination.pages}
          onPageChange={setPage}
          nextLabel={ct("next")}
          previousLabel={ct("prev")}
        />
      )}
      <CallRatingModal
        data={callRating?.value ?? 0}
        open={!!callRating}
        handleSave={async (rating) => {
          const response = await handleInternalNoteUpdate(
            {
              yourRating: rating,
            },
            callRating?.id || ""
          );

          return response?.yourRating ?? null;
        }}
        handleClose={() => {
          setCallRating(null);
        }}
      />
    </div>
  );
}
