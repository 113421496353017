export enum DashboardColumnTypes {
  Caller = "caller",
  Category = "category",
  Flags = "flags",
  Ratings = "ratings",
  Reason = "reason",
  Date = "date",
}

export enum CallCategories {
  Support = "support",
  Sales = "sales",
}

export enum CallDirection {
  Inbound = "inbound",
  Outbound = "outbound",
}

export enum CallResolution {
  Yes = "yes",
  No = "no",
  Transfer = "transferred",
}
