"use client";

import { useEffect, useState } from "react";
import clsx from "clsx";
import { Modal } from "flowbite-react";
import { useAlert } from "@/hooks/useAlert";
import Button from "@/components/library/Button";
import Icon, { ICON_TYPE } from "@/components/library/Icon";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  data: number;
  handleSave: (rating: number) => Promise<number | null>;
  handleClose: () => void;
};

const CallRatingModal: React.FC<Props> = ({
  open,
  data,
  handleSave,
  handleClose,
}) => {
  const { setIsSaving, setAlert } = useAlert();
  const { t } = useTranslation("calls");
  const { t: ct } = useTranslation("common");

  const [rating, setRating] = useState<number>(data);
  const [hoverRating, setHoverRating] = useState<number>(data);

  useEffect(() => {
    setRating(data);
    setHoverRating(data);
  }, [data, open]);

  return (
    <Modal
      show={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Modal.Header>
        <span>{t("ratingModalTitle")}</span>
        <p className={clsx("text-sm", "text-gray-700", "dark:text-gray-300")}>
          {t("ratingModalDescription")}{" "}
          {t("ratingModalCurrent", { count: data })}
        </p>
      </Modal.Header>
      <Modal.Body>
        <div className={clsx("mx-auto", "w-80")}>
          <div className={clsx("grid", "grid-cols-5", "gap-4")}>
            {[1, 2, 3, 4, 5].map((i) => (
              <div
                key={`${i}_${
                  rating >= i ? ICON_TYPE.SOLID : ICON_TYPE.REGULAR
                }_${hoverRating >= i ? ICON_TYPE.SOLID : ICON_TYPE.REGULAR}`}
                onClick={() => setRating(i)}
                onMouseOver={() => setHoverRating(i)}
                className={clsx("cursor-pointer")}
              >
                <Icon
                  icon="star"
                  type={rating >= i ? ICON_TYPE.SOLID : ICON_TYPE.REGULAR}
                  className={clsx("text-lulaNew", {
                    "text-gray-300": hoverRating < i,
                  })}
                  size={2}
                />
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className={clsx("block")}>
        <div className={clsx("!ml-0", "float-right")}>
          <Button type="secondary" onClick={() => handleClose()}>
            {ct("close")}
          </Button>
          <Button
            type="primary"
            disabled={rating === data}
            onClick={async () => {
              setIsSaving(true, t("savingRating"));
              const response = await handleSave(rating);
              setIsSaving(false, "");

              if (response === null) {
                setAlert({
                  message: t("savingRatingFailed"),
                  status: "error",
                  secondsToClose: 10,
                });
              } else {
                setRating(response);
                handleClose();
              }
            }}
            className="ml-4"
          >
            {t("saveRatingValue", { count: rating })}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CallRatingModal;
