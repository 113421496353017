import clsx from "clsx";
import { Tooltip } from "flowbite-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "@/hooks/useAlert";

type Props = {
  type: string;
  value: string;
  children?: JSX.Element | JSX.Element[] | null;
};

export default function SmartLabel({ type, children, value }: Props) {
  const { setAlert } = useAlert();

  return (
    <Tooltip content={children ?? value}>
      <CopyToClipboard
        text={value}
        onCopy={() =>
          setAlert({
            message: `Copied ${type}`,
            status: "success",
            secondsToClose: 4,
          })
        }
      >
        <div className={clsx("text-ellipsis", "overflow-hidden")}>
          {children ?? value}
        </div>
      </CopyToClipboard>
    </Tooltip>
  );
}
